import React, { useContext, useEffect } from "react";
import TransitionLink from 'gatsby-plugin-transition-link';
import anime from 'animejs';
import { Link } from "gatsby";
import { useSpring, animated } from 'react-spring';
import Github from '../svgs/github.svg';
import Linkedin from '../svgs/linkedin.svg';
import Email from '../svgs/email.svg';
import { ThreeContext } from '../three/ThreeContext';
import SEO from "../components/seo";


const About = props => {

  let pageMount = props.mount;
  const { toProjectFn, pNum, setPnum, setInProject, setFromProject, setTransition, threeStarter } = useContext(ThreeContext);

  const springProps = useSpring({
    opacity: pageMount ? 1 : 0,
    from: {
      opacity: 0
    }
  });

  useEffect(()=>{
    setInProject(false);
    setTransition(false);
    toProjectFn.updateShaders(pNum, 'profile');

  },[])

  let emailAddress = '#';

  function scrollTop(){
    
    let scrollDiv = document.getElementById('scroll-parent').children[0];
    
    anime({
      targets: scrollDiv,
      scrollTop: 0,
      // duration: 3000,
      easing: 'easeInOutExpo',
    })
  
  }

  


    


  
  return (
    <>
      <SEO location={props.location} title='Software Engineering, Design, Creative Technology' description={`I'm a software engineer with experience designing and developing web applications, leading engineering teams, and creating art from code - I thrive at the intersection of engineering, design, and technology.`} />
      <animated.div className='page__about' style={{opacity: springProps.opacity }}>
        <div className="wrap__full">
          <div className="container">
            <div className="row intro-header">
              <div className="col-12">
                <h2 className="subheading">Alexander Al-Hamdan</h2>
                <h1>10+ years Solving Problems through Code, Research, and Design</h1>
                <h2 className='summary'>I'm a software engineer with experience designing and developing web applications, leading engineering teams, and creating art from code. I thrive at the intersection of engineering, design, and technology.</h2>
                <div className="social-icons">
                  { threeStarter && <a href={`mailto:alexalhamdan@gmail.com?subject=Outreach from alexalhamdan.com`}><Email /></a> }
                  <a target='_blank' rel='noopener noreferrer'  href='https://www.linkedin.com/in/alexalhamdan/'><Linkedin /></a>
                  <a target='_blank' rel='noopener noreferrer'  href='https://github.com/alx-alh'><Github /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wrap__full about-content">
          <div className="outline-wrapper">
            <h2 className="outline-heading">ABOUT</h2>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-8 ml-auto">
                <div className="skill-list mb-5 pb-5">
                  <p className='description'>Curiosity, inquisitiveness, and entrepreneurial spirit are the driving forces of my career, which started in architectural design and has currently hit its stride in software engineering. I combine technical skills, design thinking, and excellent communication to provide value to companies by bridging the gaps between engineering, design, and business. I've learned from great teams, and believe knowledge sharing leads to better products.</p>
                  <p className='description'>As the first hire at <TransitionLink to='/projects/learncore' exit={{ length: 1, trigger: ()=> {scrollTop()} }} entry={{delay: 1, length:1 }}>LearnCore</TransitionLink>, I had the opportunity to design and develop a SaaS product from scratch. I collaborated with users and shareholders to refine, iterate, and improve the product, eventually assembling and mentoring the engineering team. Servicing 20,000+ users, 400,000+ lessons, and 10,000+ video pitches, the product was acquired for $50 million in 2018.</p>
                  <p className='description'>With experience across business development, management, engineering, and design, I see the big picture, the technical details, and everything in between. </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="wrap__full about-content">
          <div className="outline-wrapper">
            <h2 className="outline-heading">Skills</h2>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-8 ml-auto">
                <div className="skill-list mb-5 pb-5">
                  <h4 className="subheading">Engineering</h4>
                  <p className='description'>I'm a full-stack developer, with front-end expertise. I've delivered a wide range of products, including feature-rich web applications, e-commerce sites, creative portfolios, and marketing sites with automated integrations.</p>
                  <h4 className="subheading heading-color-2">Languages / Frameworks / Technologies</h4>
                  <p>HTML, CSS / SCSS, Bootstrap, Javascript, React, Three.js, JQuery, Backbone.js, Angular.js, D3.js, PHP, Scala, Python, mySQL, graphQL, SEO, Responsive Design / Development</p>
                </div>
                <div className="skill-list mb-5 pb-5">
                  <h4 className="subheading">Management</h4>
                  <p className='description'>I drive growth for my employers through highly effective communication centered on empathy for both users and engineers. Whether managing projects and delegating work to a decentralized network of subcontractors, or working in a dedicated role providing leadership for engineering teams, I believe managers should be effective at all levels of a project/team. I'm comfortable both presenting high level information to shareholders and digging into the technical details of the code itself.</p>
                  <h4 className="subheading heading-color-2">Skills / Methodologies</h4>
                  <p>Project / Product Management, Engineering Management, Mentorship, Interviewing / Hiring / On-Boarding, Agile, Scrum, Kanban</p>
                </div>
                <div className="skill-list mb-5 pb-5">
                  <h4 className="subheading">Design</h4>
                  <p className='description'>I’ve leveraged my academic and professional background in Architecture to keep design driven by critical thinking at the core of my approach. With each project, I continue to pursue design methodologies that solve problems through research, experimentation, and iteration.</p>
                  <p className='description'>Exploring the synthesis of code and architecture eventually led to the creation of a series of 3d Parametric / Generative <TransitionLink to='/projects/tempest' exit={{ length: 1, trigger: ()=> { scrollTop()} }} entry={{delay: 1, length:1 }}>Art Installations</TransitionLink>, now placed in private and public spaces around Chicago. <TransitionLink to='/projects/bkb-chicago' exit={{ length: 1, trigger: ()=> { scrollTop()} }} entry={{delay: 1, length:1 }}>These works</TransitionLink> are the physical manifestation of my combined passion for programming and architectural design.</p>
                  <h4 className="subheading heading-color-2">Skills / Software / Technologies</h4>
                  <p>UX Research / Design, UI Design, 3d Modeling / Rendering / Animation, Motion Graphics, Videography / Video Editing, Adobe CS (Photoshop, Illustrator, After Effects), 3dsMax, Rhino3d, V-Ray, AutoCAD</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="wrap__full about-content">
          <div className="outline-wrapper">
            <h2 className="outline-heading">Contact</h2>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-8 ml-auto">
                <div className="skill-list mb-5 pb-5">
                  <p className='description'>I'm currently open to select freelance projects and full-time opportunities. If you're interested in working with me or saying hello, you can contact me at the links below.</p>
                  <div className="social-icons">
                  { threeStarter && <a href={`mailto:alexalhamdan@gmail.com?subject=Outreach from alexalhamdan.com`}><Email /></a> }
                    <a target='_blank' rel='noopener noreferrer'  href='https://www.linkedin.com/in/alexalhamdan/'><Linkedin /></a>
                    <a target='_blank' rel='noopener noreferrer'  href='https://github.com/alx-alh'><Github /></a>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </animated.div>
    </>
  )
}

export default About
